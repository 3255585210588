@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.slider {
  padding-bottom: rem(128);

  h2 {
    margin-bottom: rem(32);
  }

  &__slides {
    margin-bottom: rem(32);
  }

  &__article {
    position: relative;
    overflow: hidden;
    border-radius: var(--radius-large);
  }

  &__content {
    position: absolute;
    bottom: 40px;
    z-index: 1;
    width: 70%;
    max-height: 238px;
    padding-inline: rem(32);
    overflow: hidden;

    .title {
      margin-bottom: 16px;
    }
  }

  &__link {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 2;
    }
  }

  @include media(tablet) {
    padding-bottom: rem(32);

    &__article {
      img {
        height: auto;
        aspect-ratio: 3 / 2;
      }
    }

    &__content {
      width: 85%;
    }
  }

  @include media(mobile-l) {
    h2,
    &__slides {
      margin-bottom: 24px;
    }

    &__article {
      border-radius: var(--radius-main);

      &::before {
        background: var(--gradient-intense);
      }
    }

    &__content {
      padding-inline: 16px;
      bottom: 16px;
    }
  }

  @include media(mobile-s) {
    &__article {
      img {
        aspect-ratio:  1 / 1;
      }
    }

    &__content {
      width: 100%;

      .title {
        font-size: 22px;
      }

      .intro {
        font-size: 18px;
      }
    }
  }
}