@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.product-popup {
  position: fixed;
  inset: 0;
  background-color: var(--color-gray-960);
  z-index: 15;

  &__box {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 996px;
    width: 100%;
    background-color: var(--color-white);
    transform: translate(-50%, -50%);
    border-radius: var(--radius-large);
  }

  &__image {
    position: relative;
    max-width: 440px;
    width: 100%;
    margin-right: rem(48);
    border-radius: inherit;
    height: 100%;
  }

  .products__offer {
    right: 0;
    top: 24px;
  }

  &__content {
    max-width: 476px;
    width: 100%;
    padding: rem(56) rem(26) rem(26) 0;
  }

  &__close {
    position: absolute;
    top: rem(26);
    right: rem(26);
    width: 36px;
    height: 36px;
    background-color: var(--color-blue-500);
    cursor: pointer;
    border-radius: var(--radius-full);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 21px;
      height: 21px;
      transform: translate(-50%, -50%);
      background-image: url("../images/icons/close-icon.svg");
    }
  }

  &__name {
    margin-bottom: rem(26);
    padding-right: rem(32);
    font-size: clamp(26px, 3vw ,40px);
    line-height: 1.1;
    width: var(--fw-600);
    color: var(--color-blue-500);
  }

  &__details {
    margin-bottom: 24px;

    li {
      display: flex;
      align-items: center;
      gap: rem(36);

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }

    .products__details-info {
      font-weight: var(--fw-600);
    }
  }

  &__desc {
    margin-bottom: 16px;
    line-height: 1.3;
    font-weight: var(--fw-400);
  }

  .products__price {
    font-size: clamp(30px, 3vw, 48px);

    &--old {
      font-size: clamp(26px, 3vw, 32px);
      text-decoration: line-through;
    }
  }

  .btn {
    width: 100%;
  }

  @include media(tablet-wide) {
    &__box {
      max-height: 85vh;
      width: 90vw;
      overflow-y: scroll;
    }

    &__image {
      height: auto;
      margin-right: 24px;

      img {
        height: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: rem(56) 16px 16px 0;
    }

    &__close {
      top: 16px;
      right: 16px;
    }

    &__name,
    &__details {
      margin-bottom: 16px;
    }
  }

  @include media(mobile-l) {
    &__box {
      flex-direction: column;
      padding: 10px;
      border-radius: var(--radius-main);
    }

    &__image,
    &__content {
      max-width: 100%;
    }

    &__close {
      width: 26px;
      height: 26px;

      &::before {
        width: 16px;
        height: 16px;
      }
    }

    &__image {
      margin-right: 0;

      img {
        height: auto;
        margin-inline: auto;
        aspect-ratio: 3 / 2;
        object-fit: contain;
      }
    }

    .products__offer {
      right: unset;
      left: 16px;
      top: 4px;
    }

    &__content,
    &__name {
      padding: 0;
    }
  }
}