@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  h2 {
    margin-bottom: 16px;
  }

  .services__name {
    margin-bottom: 8px;
    padding-bottom: 0;
    font-size: 24px;
    line-height: 1.3;
    color: var(--color-blue-500);
  }

  &__desc {
    max-width: 768px;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: rem(30);
    }
  }

  img {
    width: 100%;
    margin-bottom: 24px;
    border-radius: var(--radius-large);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: rem(20);
    margin-bottom: rem(32);
  }

  &__item {
    background-color: var(--color-blue-500);
    border-radius: var(--radius-main) var(--radius-main) var(--radius-large) var(--radius-large);
    overflow: hidden;

    .services__img {
      margin-bottom: 0;
      border-radius: 0;
    }
  }

  .overlay {
    &::before {
      background: var(--color-blue-560);
    }
  }

  &__wrapper {
    padding: rem(19);

    h3 {
      margin-bottom: 8px;
    }

    p {
      line-height: 1.4;
      color: var(--color-gray-300);
    }
  }

  &__details {
    margin-bottom: rem(64);
  }

  &__detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: clamp(30px, 8vw, 122px);

    &--reverse {
      flex-direction: row-reverse;
    }

    &:not(:last-of-type) {
      margin-bottom: rem(80);
    }

    img {
      max-width: 648px;
      margin-bottom: 0;
    }
  }

  &__content {
    max-width: 526px;
    width: 100%;
    margin-bottom: rem(37);

    .title {
      margin-bottom: 12px;
      color: var(--color-blue-500);
    }

    p {
      line-height: 1.4;
    }

    p:not(:last-of-type) {
      margin-bottom: 24px;
    }

    h4 {
      margin-bottom: 8px;
    }
  }

  &--home {
    .services__desc {
      margin-bottom: rem(32);
    }
  }

  &--details {
    .services__desc {
      margin-bottom: rem(64);
    }
  }

  &--portfolio {
    padding-bottom: rem(80);

    .services__desc {
      margin-bottom: rem(48);
    }

    .services__details {
      margin-bottom: 0;
    }

    .services__detail {
      align-items: flex-start;

      &:not(:last-of-type) {
        margin-bottom: rem(64);
      }
    }

    .services__content {
      margin-bottom: 0;

      .title {
        line-height: 1.3;
      }

      p {
        margin-bottom: rem(32);
      }
    }
  }

  &--single {
    padding-bottom: rem(72);

    .services__desc {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  }

  @include media(tablet-wide) {
    &__detail {
      gap: 24px;

      &:not(:last-of-type) {
        margin-bottom: rem(32);
      }
    }

    &--details {
      .services__desc {
        margin-bottom: rem(32);
      }
    }
  }

  @include media(tablet) {
    &__detail {
      flex-direction: column-reverse;

      img {
        max-width: 100%;
      }
    }

    &__content {
      max-width: 100%;
    }

    &--portfolio,
    &--single {
      padding-bottom: rem(32);
    }

    &--single {
      img {
        height: auto;
        aspect-ratio: 3 / 2;
      }
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      gap: 16px;
      margin-bottom: 24px;
    }

    &__item {
      border-radius: var(--radius-main);
    }

    &__wrapper {
      padding: 16px;
    }

    &__details {
      margin-bottom: 24px;
    }

    &__detail {
      gap: 16px;

      img {
        height: auto;
        aspect-ratio: 4/2;
        border-radius: var(--radius-main);
      }
    }

    &__content {
      margin-bottom: 0;

      p:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    &--details,
    &--home,
    &--portfolio {
      .services__desc {
        margin-bottom: 24px;
      }
    }

    &--portfolio {
      .services__desc {
        margin-bottom: 24px;
      }

      .services__detail {
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }

      .services__content {
        p {
          margin-bottom: 16px;
        }
      }
    }
  }

  @include media(mobile-m) {
    &--details {
      padding-bottom: rem(32);
    }
  }

  @include media(mobile-s) {
    &__item {
      .services__img {
        height: auto;
        aspect-ratio: 5 / 3;
      }
    }

    &--portfolio {
      .btn {
        width: 100%;
      }
    }
  }
}