@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.catalog {
  padding-bottom: rem(138);

  h2 {
    margin-bottom: rem(32);
  }

  h3 {
    margin-bottom: 12px;
    font-size: clamp(24px, 3vw, 40px);
    line-height: 1.1;
    color: var(--color-blue-500);
  }

  &__name {
    margin-bottom: 8px;
    font-size: clamp(22px, 2vw, 24px);
    line-height: 1.3;
    color: var(--color-blue-500);
  }

  &__desc {
    max-width: 746px;
    width: 100%;
    margin-bottom: rem(32);
  }

  &__promos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    gap: 24px;
    margin-bottom: 24px;
  }

  &__promo {
    padding: rem(26);
    background-color: var(--color-orange-400);
    border-radius: var(--radius-large);

    p {
      margin-bottom: rem(32);
    }

    &--slim {
      padding: 24px;

      p {
        width: 95%;
        margin-bottom: 0;
        line-height: 1.4;
      }
    }
  }

  &__promo--decor {
    position: relative;
    padding: rem(56) clamp(285px, 37.5vw, 600px) rem(56) rem(32);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      max-width: clamp(285px, 37.5vw, 600px);
      width: 100%;
      border-radius: var(--radius-large);
      background-image: url("../images/catalog/limited.jpg");
      clip-path: var(--clip-decor);
    }

    p {
      margin-bottom: 0;
    }
  }

  &__section {
    &:not(:last-of-type) {
      margin-bottom: rem(32);
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      display: inline-block;
      padding: 16px 24px;
      font-weight: var(--fw-700);
      font-size: clamp(18px, 2vw, 20px);
      line-height: 1;
      cursor: pointer;
      border-radius: var(--radius-main);
    }

    .selected {
      color: var(--color-white);
      background-color: var(--color-blue-500);
    }
  }

  @include media(tablet) {
    padding-bottom: rem(32);
  }

  @include media(mobile-l) {

    h2,
    &__desc {
      margin-bottom: 24px;
    }

    &__section {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }

    &__promos {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      gap: 16px;
    }

    &__promo {
      padding: 16px;
      border-radius: var(--radius-main);

      p {
        margin-bottom: 24px;
      }
    }

    &__promo--decor {
      padding: rem(32) clamp(285px, 37.5vw, 600px) rem(32) rem(32);

      &::before {
        border-radius: var(--radius-main);
      }
    }
  }

  @include media(mobile-m) {
    &__promo--decor {
      padding: rem(32) rem(32) 150px rem(32);
      overflow: hidden;

      &::before {
        top: unset;
        height: 150px;
        min-width: 125%;
        clip-path: var(--clip-horizontal);
      }
    }
  }

  @include media(mobile-s) {
    &__nav {
      overflow-x: auto;

      span {
        width: 100%;
        text-align: center;
        border: 2px solid var(--color-blue-500);
        border-radius: var(--radius-main);
      }
    }
  }
}