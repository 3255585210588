@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3,
h4,
.title {
  overflow: hidden;
  font-weight: var(--fw-700);
  text-transform: capitalize;
}

h1 {
  font-size: clamp(42px, 4.5vw, 72px);
  line-height: 1.2;
  letter-spacing: -0.5px;
  color: var(--color-gray-300);
}

h2 {
  font-size: clamp(34px, 3.5vw, 56px);
  line-height: 1.1;
  color: var(--color-orange-400);
}

h3 {
  font-size: clamp(24px, 3vw, 32px);
  line-height: 1.3;
  color: var(--color-orange-400);
}

h4 {
  font-size: 24px;
  line-height: 1.3;
  color: var(--color-blue-500);
}

.title {
  font-size: clamp(28px, 3vw, 48px);
  line-height: 1.1;
  color: var(--color-gray-300);
}

.desc {
  font-size: clamp(24px, 3vw, 40px);
  line-height: 1.4;
  font-weight: var(--fw-400);
  color: var(--color-gray-300);
}

.intro {
  font-size: clamp(20px, 3vw, 32px);
  line-height: 1.3;
  font-weight: var(--fw-400);
  color: var(--color-gray-300);
}

input,
textarea {
  font-size: clamp(16px, 2vw, 20px);
  line-height: 1.4;
  font-weight: var(--fw-600);
  color: var(--color-blue-500);

  &::placeholder {
    color: var(--color-blue-580);
  }
}

.copyright {
  font-family: var(--font-family-secondary);
  font-size: 16px;
  line-height: 1.68;
  font-weight: var(--fw-500);
  color: var(--color-blue-500)
}