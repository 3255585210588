@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  &__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: calc(var(--header-height) + rem(72)) 0 rem(72);
    min-height: 712px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    clip-path: var(--clip-main-l);
  }

  .overlay {
    &::before {
      background: var(--color-blue-580);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    max-width: 966px;
    width: 100%;
  }

  h1 {
    line-height: 1.3;
    margin-bottom: 16px;
  }

  &--home,
  &--portfolio {
    h1 {
      line-height: 1.2;
    }
  }

  &--home {
    .hero__content {
      max-width: 746px;
    }

    .desc {
      margin-bottom: rem(32);
    }
  }

  @include media(desktop-wide) {
    &__content {
      margin-left: rem(60);
    }
  }

  @include media(tablet-wide) {
    &__inner {
      min-height: 550px;
      padding: calc(var(--header-height) + rem(72)) 0 rem(42);
    }
  }

  @include media(tablet) {
    &__inner {
      min-height: 350px;
      padding: rem(52) 0 24px;
      clip-path: var(--clip-main-m);
    }

    &__content {
      width: 75%;
    }

    h1 {
      margin-bottom: 10px;
    }

    &--home {
      .desc {
        margin-bottom: 24px;
      }
    }
  }

  @include media(mobile-l) {
    &__content {
      width: 100%;
      margin-left: 0;
    }
  }

  @include media(mobile-m) {
    &__inner {
      padding: 24px 0;
      clip-path: var(--clip-main-s);
    }
  }
}