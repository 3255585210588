@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding-block: rem(60) rem(56);
  background-color: var(--color-orange-400);

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-bottom: 8px;

    img {
      filter: brightness(0) saturate(100%) invert(11%) sepia(86%) saturate(1695%) hue-rotate(211deg) brightness(91%) contrast(94%);
    }
  }

  &__links {
    display: none;
    align-items: center;
    margin-bottom: 24px;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }

  &__link {
    font-weight: var(--fw-700);
  }

  @include media(tablet) {
    padding: rem(30) 0;

    .logo {
      max-width: 240px;
      margin-bottom: 24px;

      img {
        height: auto;
      }
    }

    &__links {
      display: flex;
    }
  }

  @include media(mobile-s) {
    &__mail {
      flex-direction: column;
    }

    &__link {
      font-size: 16px;
    }
  }
}