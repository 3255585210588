@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  font-style: normal;
  font-size: clamp(16px, 1.5vw, 20px);
  line-height: 1.4;
  letter-spacing: 0;
  color: var(--color-blue-500);
  background-color: var(--color-gray-300);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }

  &--no-hero {
    .header {
      background-color: var(--color-blue-500);
    }

    .main {
      padding-top: var(--header-height);
    }
  }
}

section {
  padding: rem(64) 0;

  @include media(tablet) {
    padding: rem(32) 0;
  }

  @include media(mobile-m) {
    padding: rem(32) 0 0;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
    background: var(--gradient-main);
  }
}

@include media(tablet) {
  .main {
    padding-top: var(--header-height);
  }
}