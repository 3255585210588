@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 14px 22px;
  border: 2px solid var(--color-orange-400);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  font-size: clamp(16px, 1.5vw, 20px);
  line-height: 1;
  color: var(--color-blue-500) !important;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: var(--radius-main);
  background-color: var(--color-orange-400);
  transition-property: background-color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: var(--color-orange-500);
    border-color: var(--color-blue-500);
  }

  &--centered {
    display: block;
    max-width: fit-content;
    width: 100%;
    margin-inline: auto;
  }

  &--reverse {
    background-color: var(--color-blue-500);
    border-color: var(--color-blue-500);
    color: var(--color-gray-300) !important;

    &:hover {
      background-color: var(--color-blue-600);
      border-color: var(--color-orange-400);
      color: var(--color-orange-400) !important;
    }
  }
}

*[class].swiper {
  .swiper-button {
    width: 48px;
    height: 48px;
    background-color: var(--color-gray-300);
    border-radius: var(--radius-full);
    top: 54%;
    transform: translateY(-60%);

    &::after {
      @include contain-background();

      content: '';
      position: absolute;
      height: 24px;
      width: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
      background-image: url("../images/icons/arrow-icon.svg");
    }
  }

  .swiper-button-prev {
    left: 40px;

    &::after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  .swiper-button-next {
    right: 40px;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  @include media(tablet) {
    .swiper-button {
      width: 38px;
      height: 38px;
      top: 44%;
      transform: translateY(-50%);


      &::after {
        height: 20px;
        width: 20px;
      }
    }
  }

  @include media(mobile-l) {
    .swiper-button {
      transform: translateY(0%);
      top: unset;
      bottom: 6px;
      background-color: var(--color-orange-400);

      &::after {
        filter: brightness(0) saturate(100%) invert(100%) sepia(77%) saturate(137%) hue-rotate(173deg) brightness(114%) contrast(89%);
      }
    }

    .swiper-button-disabled {
      opacity: 0.5;
    }
  }

  @include media(mobile-s) {
    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }
  }
}