@import "https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400..800&family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1296px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Epilogue", sans-serif;
  --font-family-secondary: "Baloo Da 2", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #111;
  --color-orange-400: #f0bb00;
  --color-orange-500: #d99c00;
  --color-blue-500: #162b5f;
  --color-blue-560: #22335f99;
  --color-blue-580: #162b5fcc;
  --color-blue-600: #0f1e42;
  --color-gray-960: #0009;
  --color-gray-200: #c4c4c4;
  --color-gray-300: #f1f1f1;
  --radius-main: 16px;
  --radius-small: 10px;
  --radius-large: 32px;
  --radius-full: 50%;
  --shadow-primary: 0px 30px 40px 0px #d4d9e833;
  --gradient-main: linear-gradient(360deg, #0000009f 33.13%, #6666663b 61.36%);
  --gradient-intense: linear-gradient(360deg, #000000bf 60%, #66666659 100%);
  --clip-main-l: ellipse(109% 78% at 36% 22%);
  --clip-main-m: ellipse(82% 86% at 42% 14%);
  --clip-main-s: ellipse(100% 90% at 28% 16%);
  --clip-secondary-l: ellipse(110% 65% at 86% 37%);
  --clip-secondary-m: ellipse(242% 68% at 100% 32%);
  --clip-decor: polygon(19% 0, 100% 0, 100% 100%, 14% 100%);
  --clip-horizontal: polygon(0 0, 100% 24%, 100% 100%, 0% 100%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  letter-spacing: 0;
  color: var(--color-blue-500);
  background-color: var(--color-gray-300);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1.5vw, 20px);
  font-style: normal;
  line-height: 1.4;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.page--no-hero .header {
  background-color: var(--color-blue-500);
}

.page--no-hero .main {
  padding-top: var(--header-height);
}

section {
  padding: 4rem 0;
}

@media only screen and (max-width: 992px) {
  section {
    padding: 2rem 0;
  }
}

@media only screen and (max-width: 576px) {
  section {
    padding: 2rem 0 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient-main);
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .main {
    padding-top: var(--header-height);
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, h4, .title {
  font-weight: var(--fw-700);
  text-transform: capitalize;
  overflow: hidden;
}

h1 {
  letter-spacing: -.5px;
  color: var(--color-gray-300);
  font-size: clamp(42px, 4.5vw, 72px);
  line-height: 1.2;
}

h2 {
  color: var(--color-orange-400);
  font-size: clamp(34px, 3.5vw, 56px);
  line-height: 1.1;
}

h3 {
  color: var(--color-orange-400);
  font-size: clamp(24px, 3vw, 32px);
  line-height: 1.3;
}

h4 {
  color: var(--color-blue-500);
  font-size: 24px;
  line-height: 1.3;
}

.title {
  color: var(--color-gray-300);
  font-size: clamp(28px, 3vw, 48px);
  line-height: 1.1;
}

.desc {
  font-size: clamp(24px, 3vw, 40px);
  line-height: 1.4;
  font-weight: var(--fw-400);
  color: var(--color-gray-300);
}

.intro {
  font-size: clamp(20px, 3vw, 32px);
  line-height: 1.3;
  font-weight: var(--fw-400);
  color: var(--color-gray-300);
}

input, textarea {
  font-size: clamp(16px, 2vw, 20px);
  line-height: 1.4;
  font-weight: var(--fw-600);
  color: var(--color-blue-500);
}

input::placeholder, textarea::placeholder {
  color: var(--color-blue-580);
}

.copyright {
  font-family: var(--font-family-secondary);
  font-size: 16px;
  line-height: 1.68;
  font-weight: var(--fw-500);
  color: var(--color-blue-500);
}

.header {
  z-index: 10;
  width: 100%;
  background-color: #0000;
  padding: clamp(12px, 1.1875rem, 19px) 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.header.scroll {
  background-color: var(--color-blue-500);
}

@media only screen and (max-width: 992px) {
  .header {
    background-color: var(--color-blue-500);
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav img {
  height: auto;
}

.nav .logo {
  max-width: 309px;
  width: 100%;
  margin-right: 24px;
}

@media only screen and (max-width: 1180px) {
  .nav .logo {
    max-width: 250px;
    margin-right: 2.5rem;
  }
}

.nav__inner {
  max-width: 971px;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    max-width: 100%;
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-blue-500);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin: 0 2.5rem 0 0;
  padding: 0 2.5rem;
  display: flex;
}

@media only screen and (max-width: 1280px) {
  .nav__list {
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav__item {
  margin: 0 24px;
}

@media only screen and (max-width: 1180px) {
  .nav__item {
    margin: 0 24px 0 0;
  }
}

@media only screen and (max-width: 992px) {
  .nav__item {
    margin: 0 0 30px;
  }
}

.nav__link {
  font-weight: var(--fw-700);
  color: var(--color-gray-300);
  text-transform: capitalize;
  white-space: nowrap;
  line-height: 1;
  transition: color .4s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  color: var(--color-orange-400);
}

.nav__btns {
  align-items: center;
  gap: 30px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

.nav--footer {
  justify-content: center;
  margin-bottom: 2.25rem;
}

.nav--footer .nav__inner {
  justify-content: center;
}

.nav--footer .nav__list {
  margin-right: 0;
  padding-inline: 0;
}

.nav--footer .nav__item {
  margin: 0;
}

.nav--footer .nav__item:not(:last-of-type) {
  margin: 0 3.25rem 0 0;
}

.nav--footer .nav__link {
  color: var(--color-blue-500);
  border-bottom: 1px solid #0000;
  transition: border-bottom .4s ease-in-out;
}

.nav--footer .nav__link:hover {
  border-bottom-color: var(--color-blue-500);
}

@media only screen and (max-width: 992px) {
  .nav--footer {
    display: none;
  }
}

[class].btn {
  border: 2px solid var(--color-orange-400);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-700);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: var(--radius-main);
  background-color: var(--color-orange-400);
  margin: 0;
  padding: 14px 22px;
  font-size: clamp(16px, 1.5vw, 20px);
  line-height: 1;
  transition-property: background-color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
  color: var(--color-blue-500) !important;
}

[class].btn:hover {
  background-color: var(--color-orange-500);
  border-color: var(--color-blue-500);
}

[class].btn--centered {
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 100%;
  margin-inline: auto;
  display: block;
}

[class].btn--reverse {
  background-color: var(--color-blue-500);
  border-color: var(--color-blue-500);
  color: var(--color-gray-300) !important;
}

[class].btn--reverse:hover {
  background-color: var(--color-blue-600);
  border-color: var(--color-orange-400);
  color: var(--color-orange-400) !important;
}

[class].swiper .swiper-button {
  width: 48px;
  height: 48px;
  background-color: var(--color-gray-300);
  border-radius: var(--radius-full);
  top: 54%;
  transform: translateY(-60%);
}

[class].swiper .swiper-button:after {
  content: "";
  height: 24px;
  width: 24px;
  background-image: url("../images/icons/arrow-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(180deg);
}

[class].swiper .swiper-button-prev {
  left: 40px;
}

[class].swiper .swiper-button-prev:after {
  transform: translate(-50%, -50%)rotate(0);
}

[class].swiper .swiper-button-next {
  right: 40px;
}

[class].swiper .swiper-button-disabled {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  [class].swiper .swiper-button {
    width: 38px;
    height: 38px;
    top: 44%;
    transform: translateY(-50%);
  }

  [class].swiper .swiper-button:after {
    height: 20px;
    width: 20px;
  }
}

@media only screen and (max-width: 768px) {
  [class].swiper .swiper-button {
    top: unset;
    background-color: var(--color-orange-400);
    bottom: 6px;
    transform: translateY(0%);
  }

  [class].swiper .swiper-button:after {
    filter: brightness(0) saturate() invert() sepia(77%) saturate(137%) hue-rotate(173deg) brightness(114%) contrast(89%);
  }

  [class].swiper .swiper-button-disabled {
    opacity: .5;
  }
}

@media only screen and (max-width: 475px) {
  [class].swiper .swiper-button-prev {
    left: 0;
  }

  [class].swiper .swiper-button-next {
    right: 0;
  }
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-orange-400);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding: 0;
}

.hero__inner {
  padding: calc(var(--header-height)  + 4.5rem) 0 4.5rem;
  min-height: 712px;
  clip-path: var(--clip-main-l);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.hero .overlay:before {
  background: var(--color-blue-580);
}

.hero__content {
  z-index: 1;
  max-width: 966px;
  width: 100%;
  position: relative;
}

.hero h1 {
  margin-bottom: 16px;
  line-height: 1.3;
}

.hero--home h1, .hero--portfolio h1 {
  line-height: 1.2;
}

.hero--home .hero__content {
  max-width: 746px;
}

.hero--home .desc {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1440px) {
  .hero__content {
    margin-left: 3.75rem;
  }
}

@media only screen and (max-width: 1180px) {
  .hero__inner {
    min-height: 550px;
    padding: calc(var(--header-height)  + 4.5rem) 0 2.625rem;
  }
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 350px;
    clip-path: var(--clip-main-m);
    padding: 3.25rem 0 24px;
  }

  .hero__content {
    width: 75%;
  }

  .hero h1 {
    margin-bottom: 10px;
  }

  .hero--home .desc {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .hero__content {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 576px) {
  .hero__inner {
    clip-path: var(--clip-main-s);
    padding: 24px 0;
  }
}

.services h2 {
  margin-bottom: 16px;
}

.services .services__name {
  color: var(--color-blue-500);
  margin-bottom: 8px;
  padding-bottom: 0;
  font-size: 24px;
  line-height: 1.3;
}

.services__desc {
  max-width: 768px;
  width: 100%;
}

.services__desc:not(:last-of-type) {
  margin-bottom: 1.875rem;
}

.services img {
  width: 100%;
  border-radius: var(--radius-large);
  margin-bottom: 24px;
}

.services__grid {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 1.25rem;
  margin-bottom: 2rem;
  display: grid;
}

.services__item {
  background-color: var(--color-blue-500);
  border-radius: var(--radius-main) var(--radius-main) var(--radius-large) var(--radius-large);
  overflow: hidden;
}

.services__item .services__img {
  border-radius: 0;
  margin-bottom: 0;
}

.services .overlay:before {
  background: var(--color-blue-560);
}

.services__wrapper {
  padding: 1.1875rem;
}

.services__wrapper h3 {
  margin-bottom: 8px;
}

.services__wrapper p {
  color: var(--color-gray-300);
  line-height: 1.4;
}

.services__details {
  margin-bottom: 4rem;
}

.services__detail {
  justify-content: space-between;
  align-items: center;
  gap: clamp(30px, 8vw, 122px);
  display: flex;
}

.services__detail--reverse {
  flex-direction: row-reverse;
}

.services__detail:not(:last-of-type) {
  margin-bottom: 5rem;
}

.services__detail img {
  max-width: 648px;
  margin-bottom: 0;
}

.services__content {
  max-width: 526px;
  width: 100%;
  margin-bottom: 2.3125rem;
}

.services__content .title {
  color: var(--color-blue-500);
  margin-bottom: 12px;
}

.services__content p {
  line-height: 1.4;
}

.services__content p:not(:last-of-type) {
  margin-bottom: 24px;
}

.services__content h4 {
  margin-bottom: 8px;
}

.services--home .services__desc {
  margin-bottom: 2rem;
}

.services--details .services__desc {
  margin-bottom: 4rem;
}

.services--portfolio {
  padding-bottom: 5rem;
}

.services--portfolio .services__desc {
  margin-bottom: 3rem;
}

.services--portfolio .services__details {
  margin-bottom: 0;
}

.services--portfolio .services__detail {
  align-items: flex-start;
}

.services--portfolio .services__detail:not(:last-of-type) {
  margin-bottom: 4rem;
}

.services--portfolio .services__content {
  margin-bottom: 0;
}

.services--portfolio .services__content .title {
  line-height: 1.3;
}

.services--portfolio .services__content p {
  margin-bottom: 2rem;
}

.services--single {
  padding-bottom: 4.5rem;
}

.services--single .services__desc:not(:last-of-type) {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1180px) {
  .services__detail {
    gap: 24px;
  }

  .services__detail:not(:last-of-type), .services--details .services__desc {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 992px) {
  .services__detail {
    flex-direction: column-reverse;
  }

  .services__detail img, .services__content {
    max-width: 100%;
  }

  .services--portfolio, .services--single {
    padding-bottom: 2rem;
  }

  .services--single img {
    height: auto;
    aspect-ratio: 3 / 2;
  }
}

@media only screen and (max-width: 768px) {
  .services__grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 16px;
    margin-bottom: 24px;
  }

  .services__item {
    border-radius: var(--radius-main);
  }

  .services__wrapper {
    padding: 16px;
  }

  .services__details {
    margin-bottom: 24px;
  }

  .services__detail {
    gap: 16px;
  }

  .services__detail img {
    height: auto;
    aspect-ratio: 4 / 2;
    border-radius: var(--radius-main);
  }

  .services__content {
    margin-bottom: 0;
  }

  .services__content p:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .services--details .services__desc, .services--home .services__desc, .services--portfolio .services__desc, .services--portfolio .services__desc {
    margin-bottom: 24px;
  }

  .services--portfolio .services__detail {
    align-items: center;
  }

  .services--portfolio .services__detail:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .services--portfolio .services__content p {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .services--details {
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 475px) {
  .services__item .services__img {
    height: auto;
    aspect-ratio: 5 / 3;
  }

  .services--portfolio .btn {
    width: 100%;
  }
}

.slider {
  padding-bottom: 8rem;
}

.slider h2, .slider__slides {
  margin-bottom: 2rem;
}

.slider__article {
  border-radius: var(--radius-large);
  position: relative;
  overflow: hidden;
}

.slider__content {
  z-index: 1;
  width: 70%;
  max-height: 238px;
  padding-inline: 2rem;
  position: absolute;
  bottom: 40px;
  overflow: hidden;
}

.slider__content .title {
  margin-bottom: 16px;
}

.slider__link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .slider {
    padding-bottom: 2rem;
  }

  .slider__article img {
    height: auto;
    aspect-ratio: 3 / 2;
  }

  .slider__content {
    width: 85%;
  }
}

@media only screen and (max-width: 768px) {
  .slider h2, .slider__slides {
    margin-bottom: 24px;
  }

  .slider__article {
    border-radius: var(--radius-main);
  }

  .slider__article:before {
    background: var(--gradient-intense);
  }

  .slider__content {
    padding-inline: 16px;
    bottom: 16px;
  }
}

@media only screen and (max-width: 475px) {
  .slider__article img {
    aspect-ratio: 1 / 1;
  }

  .slider__content {
    width: 100%;
  }

  .slider__content .title {
    font-size: 22px;
  }

  .slider__content .intro {
    font-size: 18px;
  }
}

.reviews {
  background-color: var(--color-blue-500);
  clip-path: var(--clip-secondary-l);
  padding-bottom: 8.125rem;
}

.reviews h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.reviews__items {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 1.25rem;
  display: grid;
}

.reviews__item {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.reviews__item img {
  border-radius: var(--radius-full);
  margin-bottom: 6px;
}

.reviews__item .intro {
  font-weight: var(--fw-600);
  color: var(--color-orange-400);
}

.reviews__item span {
  color: var(--color-gray-300);
  line-height: 1.3;
}

.reviews__text {
  min-height: 246px;
  background-color: var(--color-gray-200);
  border-radius: var(--radius-small);
  box-shadow: var(--shadow-primary);
  margin-bottom: 1.8125rem;
  padding: 1.25rem;
  position: relative;
}

.reviews__text:before {
  content: "";
  border-style: solid;
  border-width: 0 15px 26px;
  border-color: transparent transparent var(--color-gray-200) transparent;
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translateX(-50%)rotate(180deg);
}

.reviews__text p {
  text-align: center;
  line-height: 1.4;
}

@media only screen and (max-width: 768px) {
  .reviews {
    clip-path: var(--clip-secondary-m);
    padding-bottom: 2rem;
  }

  .reviews h2 {
    margin-bottom: 24px;
  }

  .reviews__items {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 16px;
  }

  .reviews__text {
    min-height: auto;
    margin-bottom: 24px;
    padding: 20px 16px;
  }
}

.catalog {
  padding-bottom: 8.625rem;
}

.catalog h2 {
  margin-bottom: 2rem;
}

.catalog h3 {
  color: var(--color-blue-500);
  margin-bottom: 12px;
  font-size: clamp(24px, 3vw, 40px);
  line-height: 1.1;
}

.catalog__name {
  color: var(--color-blue-500);
  margin-bottom: 8px;
  font-size: clamp(22px, 2vw, 24px);
  line-height: 1.3;
}

.catalog__desc {
  max-width: 746px;
  width: 100%;
  margin-bottom: 2rem;
}

.catalog__promos {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 24px;
  margin-bottom: 24px;
  display: grid;
}

.catalog__promo {
  background-color: var(--color-orange-400);
  border-radius: var(--radius-large);
  padding: 1.625rem;
}

.catalog__promo p {
  margin-bottom: 2rem;
}

.catalog__promo--slim {
  padding: 24px;
}

.catalog__promo--slim p {
  width: 95%;
  margin-bottom: 0;
  line-height: 1.4;
}

.catalog__promo--decor {
  padding: 3.5rem clamp(285px, 37.5vw, 600px) 3.5rem 2rem;
  position: relative;
}

.catalog__promo--decor:before {
  content: "";
  max-width: clamp(285px, 37.5vw, 600px);
  width: 100%;
  border-radius: var(--radius-large);
  clip-path: var(--clip-decor);
  background-image: url("../images/catalog/limited.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.catalog__promo--decor p {
  margin-bottom: 0;
}

.catalog__section:not(:last-of-type) {
  margin-bottom: 2rem;
}

.catalog__nav {
  align-items: center;
  gap: 8px;
  display: flex;
}

.catalog__nav span {
  font-weight: var(--fw-700);
  cursor: pointer;
  border-radius: var(--radius-main);
  padding: 16px 24px;
  font-size: clamp(18px, 2vw, 20px);
  line-height: 1;
  display: inline-block;
}

.catalog__nav .selected {
  color: var(--color-white);
  background-color: var(--color-blue-500);
}

@media only screen and (max-width: 992px) {
  .catalog {
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .catalog h2, .catalog__desc, .catalog__section:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .catalog__promos {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 16px;
  }

  .catalog__promo {
    border-radius: var(--radius-main);
    padding: 16px;
  }

  .catalog__promo p {
    margin-bottom: 24px;
  }

  .catalog__promo--decor {
    padding: 2rem clamp(285px, 37.5vw, 600px) 2rem 2rem;
  }

  .catalog__promo--decor:before {
    border-radius: var(--radius-main);
  }
}

@media only screen and (max-width: 576px) {
  .catalog__promo--decor {
    padding: 2rem 2rem 150px;
    overflow: hidden;
  }

  .catalog__promo--decor:before {
    top: unset;
    height: 150px;
    min-width: 125%;
    clip-path: var(--clip-horizontal);
  }
}

@media only screen and (max-width: 475px) {
  .catalog__nav {
    overflow-x: auto;
  }

  .catalog__nav span {
    width: 100%;
    text-align: center;
    border: 2px solid var(--color-blue-500);
    border-radius: var(--radius-main);
  }
}

.products {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  gap: 24px;
  display: grid;
}

.products--three {
  grid-template-columns: repeat(3, 1fr);
}

.products__item {
  height: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-large);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  overflow: hidden;
}

.products__img {
  margin: 0 auto;
}

.products__offer {
  font-weight: var(--fw-700);
  background-color: var(--color-orange-400);
  border-radius: var(--radius-large);
  padding: 6px 8px;
  line-height: 1;
  position: absolute;
  top: 16px;
  right: 16px;
}

.products__info {
  padding: 16px 24px 20px;
}

.products__details, .products__desc {
  display: none;
}

.products__name {
  max-height: 51px;
  font-weight: var(--fw-600);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: clamp(18px, 2vw, 20px);
  display: -webkit-box;
  overflow: hidden;
}

.products__bottom {
  padding: 0 24px 24px;
}

.products__prices, .products .btn {
  align-self: flex-end;
}

.products__prices {
  width: 100%;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
  display: flex;
}

.products__price {
  font-size: clamp(24px, 2vw, 32px);
  font-weight: var(--fw-700);
  line-height: 1.3;
}

.products__price--old {
  opacity: .8;
  font-size: clamp(16px, 2vw, 20px);
  text-decoration: line-through;
}

.products .btn {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .products__info {
    padding: 16px 10px;
  }

  .products__bottom {
    padding: 0 10px 16px;
  }

  .products__item {
    border-radius: var(--radius-main);
  }
}

@media only screen and (max-width: 576px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }

  .products--three {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

@media only screen and (max-width: 475px) {
  .products {
    grid-template-columns: 1fr;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.request {
  padding: 5rem 0;
  position: relative;
}

.request__wrapper {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: -186px 0 0;
}

.request__inner {
  max-width: 856px;
  width: 100%;
  text-align: center;
  background-color: var(--color-orange-400);
  border-radius: var(--radius-large);
  margin-inline: auto;
  padding: 4.75rem 5rem;
}

.request__inner h2 {
  color: var(--color-blue-500);
  margin-bottom: 1.875rem;
}

.request__form {
  text-align: left;
  flex-direction: column;
  display: flex;
}

.request__input {
  margin-bottom: 16px;
}

.request__textarea {
  margin-bottom: 1.125rem;
}

.request input, .request textarea {
  width: 100%;
  background-color: var(--color-gray-300);
  border-radius: var(--radius-main);
  padding: 16px;
}

.request textarea {
  height: 240px;
  resize: none;
  border: none;
}

@media only screen and (max-width: 768px) {
  .request {
    padding: 3.25rem 0 2rem;
  }

  .request__wrapper {
    inset: -60px 0 0;
  }

  .request__inner {
    padding: 3.25rem 2rem 2rem;
  }

  .request h2 {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .request {
    padding: 2rem 0;
  }

  .request__inner, .request input, .request textarea {
    padding: 16px 10px;
  }

  .request textarea {
    height: 180px;
  }
}

.product-popup {
  background-color: var(--color-gray-960);
  z-index: 15;
  position: fixed;
  inset: 0;
}

.product-popup__box {
  max-width: 996px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-large);
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-popup__image {
  max-width: 440px;
  width: 100%;
  border-radius: inherit;
  height: 100%;
  margin-right: 3rem;
  position: relative;
}

.product-popup .products__offer {
  top: 24px;
  right: 0;
}

.product-popup__content {
  max-width: 476px;
  width: 100%;
  padding: 3.5rem 1.625rem 1.625rem 0;
}

.product-popup__close {
  width: 36px;
  height: 36px;
  background-color: var(--color-blue-500);
  cursor: pointer;
  border-radius: var(--radius-full);
  position: absolute;
  top: 1.625rem;
  right: 1.625rem;
}

.product-popup__close:before {
  content: "";
  width: 21px;
  height: 21px;
  background-image: url("../images/icons/close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-popup__name {
  width: var(--fw-600);
  color: var(--color-blue-500);
  margin-bottom: 1.625rem;
  padding-right: 2rem;
  font-size: clamp(26px, 3vw, 40px);
  line-height: 1.1;
}

.product-popup__details {
  margin-bottom: 24px;
}

.product-popup__details li {
  align-items: center;
  gap: 2.25rem;
  display: flex;
}

.product-popup__details li:not(:last-of-type) {
  margin-bottom: 8px;
}

.product-popup__details .products__details-info {
  font-weight: var(--fw-600);
}

.product-popup__desc {
  line-height: 1.3;
  font-weight: var(--fw-400);
  margin-bottom: 16px;
}

.product-popup .products__price {
  font-size: clamp(30px, 3vw, 48px);
}

.product-popup .products__price--old {
  font-size: clamp(26px, 3vw, 32px);
  text-decoration: line-through;
}

.product-popup .btn {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .product-popup__box {
    max-height: 85vh;
    width: 90vw;
    overflow-y: scroll;
  }

  .product-popup__image {
    height: auto;
    margin-right: 24px;
  }

  .product-popup__image img {
    height: 100%;
  }

  .product-popup__content {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.5rem 16px 16px 0;
    display: flex;
  }

  .product-popup__close {
    top: 16px;
    right: 16px;
  }

  .product-popup__name, .product-popup__details {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .product-popup__box {
    border-radius: var(--radius-main);
    flex-direction: column;
    padding: 10px;
  }

  .product-popup__image, .product-popup__content {
    max-width: 100%;
  }

  .product-popup__close {
    width: 26px;
    height: 26px;
  }

  .product-popup__close:before {
    width: 16px;
    height: 16px;
  }

  .product-popup__image {
    margin-right: 0;
  }

  .product-popup__image img {
    height: auto;
    aspect-ratio: 3 / 2;
    object-fit: contain;
    margin-inline: auto;
  }

  .product-popup .products__offer {
    right: unset;
    top: 4px;
    left: 16px;
  }

  .product-popup__content, .product-popup__name {
    padding: 0;
  }
}

.policy {
  max-width: 746px;
  width: 100%;
  padding-bottom: 5rem;
}

.policy h1 {
  color: var(--color-orange-400);
  margin-bottom: 24px;
}

.policy h2 {
  color: var(--color-blue-500);
  font-size: clamp(26px, 3vw, 32px);
}

.policy > :not(:last-child), .policy .policy__content:not(:last-child), .policy .policy__content > :not(:last-child) {
  margin-bottom: clamp(24px, 3vw, 40px);
}

.policy ul {
  padding-left: 2rem;
}

@media only screen and (max-width: 992px) {
  .policy ul {
    padding-left: 16px;
  }
}

.policy li {
  list-style: disc;
}

.policy a {
  color: var(--color-orange-400);
  text-decoration: underline;
}

@media only screen and (max-width: 1280px) {
  .policy {
    max-width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .policy {
    padding-bottom: 2rem;
  }
}

.footer {
  background-color: var(--color-orange-400);
  padding-block: 3.75rem 3.5rem;
}

.footer__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer .logo {
  margin-bottom: 8px;
}

.footer .logo img {
  filter: brightness(0) saturate() invert(11%) sepia(86%) saturate(1695%) hue-rotate(211deg) brightness(91%) contrast(94%);
}

.footer__links {
  align-items: center;
  margin-bottom: 24px;
  display: none;
}

.footer__item:not(:last-of-type) {
  margin-right: 16px;
}

.footer__link {
  font-weight: var(--fw-700);
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 1.875rem 0;
  }

  .footer .logo {
    max-width: 240px;
    margin-bottom: 24px;
  }

  .footer .logo img {
    height: auto;
  }

  .footer__links {
    display: flex;
  }
}

@media only screen and (max-width: 475px) {
  .footer__mail {
    flex-direction: column;
  }

  .footer__link {
    font-size: 16px;
  }
}

/*# sourceMappingURL=main.css.map */
