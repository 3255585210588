@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: clamp(12px, rem(19), 19px) 0;
  width: 100%;
  background-color: transparent;
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-100%);
  }

  &.scroll {
    background-color: var(--color-blue-500);
  }

  @include media(tablet) {
    background-color: var(--color-blue-500);
  }
}