@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  gap: 24px;

  &--three {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    background-color: var(--color-white);
    border-radius: var(--radius-large);
  }

  &__img {
    margin: 0 auto;
  }

  &__offer {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 6px 8px;
    font-weight: var(--fw-700);
    line-height: 1;
    background-color: var(--color-orange-400);
    border-radius: var(--radius-large);
  }

  &__info {
    padding: 16px 24px 20px 24px;
  }

  &__details,
  &__desc {
    display: none;
  }

  &__name {
    display: -webkit-box;
    max-height: 51px;
    overflow: hidden;
    font-weight: var(--fw-600);
    font-size: clamp(18px, 2vw, 20px);
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__bottom {
    padding: 0 24px 24px 24px;
  }

  &__prices,
  .btn {
    align-self: flex-end;
  }

  &__prices {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin-bottom: 4px;
  }

  &__price {
    font-size: clamp(24px, 2vw, 32px);
    font-weight: var(--fw-700);
    line-height: 1.3;

    &--old {
      font-size: clamp(16px, 2vw, 20px);
      text-decoration: line-through;
      opacity: 0.8;
    }
  }

  .btn {
    width: 100%;
  }

  @include media(tablet) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media(mobile-l) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    &__info {
      padding: 16px 10px;
    }

    &__bottom {
      padding: 0 10px 16px 10px;
    }

    &__item {
      border-radius: var(--radius-main);
    }
  }

  @include media(mobile-m) {
    grid-template-columns: repeat(2, 1fr);

    &--three {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }
  }

  @include media(mobile-s) {
    grid-template-columns: 1fr;
  }
}